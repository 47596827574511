import React from "react"
import {Link, Trans} from 'gatsby-plugin-react-i18next';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="four__wrapper">
      <h1>Page Not Found</h1>
      <Link to="/">
        <Logo logoClass="logo--text"></Logo>
      </Link>

    </div>
    
  </>
)

export default NotFoundPage
